<template>
	
</template>

<script>
	export default {
		name: 'menu-item-save'
	};
</script>

<style scoped lang=scss>

</style>
