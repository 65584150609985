<template>
	<div class="page">
		<v-card class="mt-4">
			<v-card-title>
				Menu: {{item.title}}
				<v-spacer></v-spacer>
				<v-btn @click="onSave(null)"
						class="ml-3 mb-2 table-custom--btn"
						color="success">
					<v-icon left>add</v-icon>
					Add menu item
				</v-btn>
			</v-card-title>
			<div class="page__subtitle" v-if="item.description">{{item.description}}</div>
			<v-card-text>
				<!--<v-data-table-->
						<!--:loading="loadingGetItem"-->
						<!--:loading-text="$t('loadingDataWaiting')"-->
						<!--:headers="headers"-->
						<!--:items="list"-->
						<!--hide-default-footer-->
						<!--class="elevation-1">-->
					<!--<template v-slot:item.actions="{ item }">-->
						<!--<div style="width: 80px;">-->
							<!--<v-btn @click="onSave(item)"-->
									<!--fab-->
									<!--link-->
									<!--color="primary"-->
									<!--x-small>-->
								<!--<v-icon x-small>-->
									<!--mdi-pencil-->
								<!--</v-icon>-->
							<!--</v-btn>-->
							<!--<v-btn fab-->
									<!--class="ml-2"-->
									<!--color="red"-->
									<!--@click="onDelete(item)"-->
									<!--x-small>-->
								<!--<v-icon-->
										<!--x-small-->
										<!--color="white">-->
									<!--mdi-delete-->
								<!--</v-icon>-->
							<!--</v-btn>-->
						<!--</div>-->
					<!--</template>-->
				<!--</v-data-table>-->
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
	import {find, forIn, debounce} from 'lodash';
	import {mapState, mapGetters, mapMutations, mapActions, } from 'vuex';
	import MenuItemSave from './MenuItemSave';

	export default {
		name: 'menu-item',
		props: ['id'],
		data(){
			return{
				headers: [
					{
						text: 'ID',
						value: 'id',
					}
				]
			}
		},

		created(){
			this.getItem();
		},

		computed:{
			...mapState({
				item: state => state.administrationsMenus.item,
				loadingGetItem: state => state.administrationsMenus.loadingGetItem,
			})
		},

		methods:{
			...mapActions({
				GET_ITEM: 'administrationsMenus/GET_ITEM',
			}),

			onSave(item){
				this.$modal.show(MenuItemSave,{
					currentItem: item,
				}, $MODAL_OPTIONS.default);
			},

			onDelete(item){
				this.DELETE_ITEM(item.id);
			},

			getItem(){
				this.GET_ITEM(this.id)
			}
		}
	};
</script>

<style scoped lang=scss>
	.page{
		&__subtitle{
			font-size: 14px;
			color: #8d8d8d;
			padding: 0 16px;
		}
	}
</style>
